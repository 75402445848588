
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}

.section-lowtoppad {
  padding-top: 0rem;
  padding-bottom: 3rem;
}

.section-lowbotpad {
  padding-top: 3rem;
  padding-bottom: 0rem;
}

.section-eqpad {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.maxw-container-fluid {
 max-width: 1080px !important;
}

.padl{
  padding-left: 7%;
}

.textr {
  text-align: right !important;
}

.textl {
  text-align: left !important;
}

.section {
  padding-top: 3rem;
  padding-bottom: 6rem;
}

.step-icon{
  width: 3rem;
}

.navbar {
  min-height: 57px;
}

.navbar-light .social{
  color: rgba(255, 255, 255, .65);
}

.navbar-stick-dark.stick .social{
  color: rgba(117, 117, 117, .8);
}

.navbar-light .nav-navbar>.nav-link.elsoft {
  color: #87cefa;
}

.navbar-light .nav-navbar>.nav-link.elsoft:hover {
  color: #00ffff;
  
}

.navbar-stick-dark.stick .nav-navbar>.nav-link.elsoft {
  color: #3c96ff;
}

.navbar-stick-dark.stick .nav-navbar>.nav-link.elsoft:hover {
  color: #007bff;
  
}

.text-help {
  color: #007bff; /* Bootstrap primary blue for example */
}

.media-body {
  cursor: pointer;
}

.media:hover{  
  h5{
    color: blue;
  }
  
}

.media:hover::before i{
  transform: scale(1.1);
  //box-shadow: 0 0 15px blue;
}

.media:hover i{
  //color: blue;
  //box-shadow: 0 0 5px blue;
  text-shadow: 0 0 3px #00aafb;
}

.media:hover .fa-circle-question {
  color: blue;
  text-shadow: none;
}

.text-sand {
  color: #da9900;
}

.mr-2-0 {
  margin-right: 2rem;
}

.mr-2-3 {
  margin-right: 2.3rem;
}

.mr-2-2 {
  margin-right: 2.2rem;
}

.mr-1-7 {
  margin-right: 1.7rem;
}

.mr-1-5 {
  margin-right: 1.5rem;
}

.mr-1-4 {
  margin-right: 1.4rem;
}
.mr-1-3 {
  margin-right: 1.3rem;
}

.text-red {
  color: red;
}

.text-litebrown {
  color: #d2b48c;
}

.text-brown {
  color: brown;
}

.text-blue {
  color: blue;
}

.text-orange{
  color: orange;  
}

.text-darkgray{
  color: #333;
}

.mx-auto{
  max-width: 508px;
}

.no-events {
  pointer-events: none;
}

.no-events a, .no-events img {
  pointer-events: auto;
}

.nopadding {
  padding: 0 !important;
}

.section-header {
  margin-bottom: 0;
}

.media-tech-logo-css
{
    height: 30px;
    min-width: 120px;
}

/* ============================================================== 
    # Integrated Tools Style
=================================================================== */

.tools-integrations {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.tools-list .icon {
	height: 100px;
	width: 100px;
	background: var(--white);
	box-shadow: 0 3px 25px 0 rgb(0 0 0 / 10%);
	border-radius: 10px;
	font-size: 50px;
	position: relative;
	border: 2px solid #e3ecf6;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.tools-list i.fa-google {
	color: #db3236;
}

.tools-list i.fa-trello {
	color: #0079bf;
}

.tools-list i.fa-salesforce {
	color: #1798c1;
}

.tools-list i.fa-file-spreadsheet {
	color: #19a563;
}

.tools-list i.fa-slack {
	color: #36C5F0;
}

.tools-list i.fa-whatsapp {
	color: #4bc55a;
}

.tools-list i.fa-jira {
	color: #2684ff;
}

.tools-list i.fa-google-drive {
	color: #00ac47;
}

.tools-list i.fa-video {
	color: #2d8cff;
}

.tools-list i.fa-facebook-messenger {
	color: #0078FF;
}

.tools-list li:nth-child(2) i {
	position: relative;
}

.tools-list li:nth-child(4) i {
	top: 50px;
}

.tools-list li:nth-child(7) i {
	top: -50px;
}

.tools-list li:nth-child(10) i {
	top: 50px;
}

.tools-list li {
	text-align: center;
	position: relative;
	display: inline-block;
	padding: 20px;
}

.tools-list li:nth-child(2n) .icon {
	width: 180px;
	height: 180px;
	line-height: 180px;
	font-size: 80px;
	position: relative;
}

.tools-list li:nth-child(3) .icon {
	top: 70px;
}

.tools-list li:first-child .icon {
	top: 70px;
}

.tools-list li:nth-child(4) .icon {
	top: 50px;
}

.tools-list li:nth-child(10) .icon {
	top: 50px;
}

.tools-list li:nth-child(5) .icon {
	position: relative;
	top: -50px;
}

.tools-list li:nth-child(6) .icon {
	top: 50px;
}

.tools-list li:nth-child(8) .icon {
	top: 50px;
}

.tools-list li:nth-child(9) .icon {
	top: 50px;
}

.tools-list .icon img {
	height: 45px;
}

.tools-list li:nth-child(2n) .icon img {
	height: 58px;
}

.tools-integrations {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.recap{
  margin: 5px 65px;
}

.media-tech-logo{
  //width: 100px;
  height: 30px;
}

.media-tech-logo-now{
  height: 25px;
}

.media-tech-logo-40{
  height: 30px;
}

.media-ico{
  width: 30px;
  height: 30px;
}

.media-title-text{
  margin-top: .5rem;
}

.blackfill{
  filter: brightness(0) saturate(100%) invert(0%) sepia(100%) hue-rotate(81deg) contrast(104%);
}

.block-number {
  text-align: center;
  font-weight: bold;
  font-size: 0.8125em;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #191919;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out; }
  .block-number:hover {
    opacity: 1; }
  .block-number::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 6px;
    margin-bottom: 2px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid #191919; }
  .block-number a:hover,
  .block-number a:focus {
    color: #191919; }

.demo-section .main-content .section,
.demo-topbar .main-content .topbar,
.demo-navbar .main-content .navbar,
.demo-header .main-content .header,
.demo-footer .main-content .footer {
  margin: 30px 20px 100px;
  border: 1px solid #f5f6f7;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.05); }

.demo-topbar .main-content .topbar,
.demo-navbar .main-content .navbar {
  position: static;
  margin: 0; }

.sample-blocks img {
  border-radius: 0.25rem; }
  .sample-blocks img.aos-animate {
    opacity: 0.5 !important; }
  .sample-blocks img:hover {
    opacity: 1 !important;
    -webkit-transition: 0.7s !important;
    transition: 0.7s !important; }

.sample-blocks a {
  position: absolute; 
}

.avatarlg{
  width: 70px;
  height: 70px;
}

.sample-blocks a:nth-child(1) {
  width: 300px;
  height: 196px;
  right: 10px;
  bottom: 40px; /* 0px + 55px */
}

.sample-blocks a:nth-child(2) {
  width: 260px;
  height: 134px;
  right: 320px;
  bottom: 70px; /* 30px + 55px */
}

.sample-blocks a:nth-child(3) {
  width: 260px;
  height: 170px;
  right: 5px;
  bottom: 285px; /* 230px + 55px */
}

.sample-blocks a:nth-child(4) {
  width: 260px;
  height: 134px;
  right: 280px;
  bottom: 280px; /* 220px + 55px */
}

.sample-blocks a:nth-child(5) {
  width: 240px;
  height: 116px;
  right: 270px;
  bottom: 485px; /* 430px + 55px */
}

.sample-blocks a:nth-child(6) {
  width: 220px;
  height: 92px;
  right: 20px;
  bottom: 510px; /* 435px + 55px */
}

.sample-blocks a:nth-child(7) {
  width: 190px;
  height: 99px;
  right: 200px;
  bottom: 665px; /* 610px + 55px */
}

.sample-blocks a:nth-child(8) {
  width: 160px;
  height: 87px;
  right: 10px;
  bottom: 608px; /* 580px + 55px */
}

.sample-blocks a:nth-child(9) {
  width: 130px;
  height: 67px;
  right: 5px;
  bottom: 715px; /* 660px + 55px */
}

.sample-blocks a:nth-child(10) {
  width: 300px;
  height: 196px;
  left: 15px;
  bottom: 35px; /* -20px + 55px */
}

.sample-blocks a:nth-child(11) {
  width: 100px;
  height: 134px;
  left: 320px;
  bottom: 85px; /* 30px + 55px */
}

.sample-blocks a:nth-child(12) {
  width: 260px;
  height: 170px;
  left: 5px;
  bottom: 265px; /* 210px + 55px */
}

.sample-blocks a:nth-child(13) {
  width: 85px;
  height: 134px;
  left: 280px;
  bottom: 275px; /* 220px + 55px */
}

.sample-blocks a:nth-child(14) {
  width: 80px;
  height: 92px;
  left: 170px;
  bottom: 490px; /* 420px + 55px */
}

.sample-blocks a:nth-child(15) {
  width: 115px;
  height: 116px;
  left: 20px;
  bottom: 485px; /* 415px + 55px */
}

.sample-blocks a:nth-child(16) {
  left: 200px;
  bottom: 645px; /* 590px + 55px */
}

.sample-blocks a:nth-child(17) {
  left: 10px;
  bottom: 615px; /* 560px + 55px */
}

.sample-blocks a:nth-child(18) {
  left: 0px;
  bottom: 695px; /* 640px + 55px */
}

  
// @-webkit-keyframes wobble {
//   0% {
//     -webkit-transform: rotate(-1deg) translate(-5px, -5px);
//             transform: rotate(-1deg) translate(-5px, -5px); }
//   50% {
//     -webkit-transform: rotate(1deg) translate(5px, 5px);
//             transform: rotate(1deg) translate(5px, 5px); }
//   100% {
//     -webkit-transform: rotate(-1deg) translate(-5px, -5px);
//             transform: rotate(-1deg) translate(-5px, -5px); } }

// @keyframes wobble {
//   0% {
//     -webkit-transform: rotate(-1deg) translate(-5px, -5px);
//             transform: rotate(-1deg) translate(-5px, -5px); }
//   50% {
//     -webkit-transform: rotate(1deg) translate(5px, 5px);
//             transform: rotate(1deg) translate(5px, 5px); }
//   100% {
//     -webkit-transform: rotate(-1deg) translate(-5px, -5px);
//             transform: rotate(-1deg) translate(-5px, -5px); } }

@keyframes wobble {
  0%, 100% {
      transform: translate(-2px, -2px);
  }
  50% {
      transform: translate(2px, 2px);
  }
}

@keyframes wobble {
  0%, 100% {
      transform: translate3d(-2px, -2px, 0);
  }
  50% {
      transform: translate3d(2px, 2px, 0);
  }
}

// .sample-blocks a:nth-child(odd) {
//   // -webkit-animation: wobble 4s infinite;
//   // animation: wobble 4s infinite;
//   will-change: transform;
//   /* or, if `will-change` causes too many resources to be used */
//   //transform: translateZ(0); /* or `translate3d(0, 0, 0)` to force hardware acceleration */

//   animation: wobble 5s infinite linear;
//   //position: relative; /* Ensure transform properties work as expected */
// }

// .sample-blocks a:nth-child(even) {
//   -webkit-animation: wobble 7s infinite;
//   animation: wobble 7s infinite;
// }


/* Example of a minimal impact version using translate3d to always engage GPU */
// .sample-blocks a:nth-child(odd) {
//   /* Using translate3d to ensure GPU acceleration */
//   transform: translate3d(0, 0, 0);
//   animation: wobble 5s infinite linear;
// }

@media (max-width: 1920px) {

  .maxw-container-fluid {
    max-width: 800px !important;
   }
   
   .padl{
    padding-left: 3%;
   }

   .sample-blocks a:nth-child(1) {
    width: 150px; /* 300 / 2 */
    height: 98px; /* 196 / 2 */
    right: 15px;
    bottom: 23px; /* 0px + 30px */
}

.sample-blocks a:nth-child(2) {
    width: 130px; /* 260 / 2 */
    height: 67px; /* 134 / 2 */
    right: 200px; /* 320 / 1.6 */
    bottom: 32px; /* 30px + 30px */
}

.sample-blocks a:nth-child(3) {
    width: 130px; /* 260 / 2 */
    height: 85px; /* 170 / 2 */
    right: 10px;
    bottom: 139.52px; /* 109.52px + 30px */
}

.sample-blocks a:nth-child(4) {
    width: 130px; /* 260 / 2 */
    height: 67px; /* 134 / 2 */
    right: 175px; /* 280 / 1.6 */
    bottom: 134.76px; /* 104.76px + 30px */
}

.sample-blocks a:nth-child(5) {
    width: 120px; /* 240 / 2 */
    height: 58px; /* 116 / 2 */
    right: 168.75px; /* 270 / 1.6 */
    bottom: 234.76px; /* 204.76px + 30px */
}

.sample-blocks a:nth-child(6) {
    width: 110px; /* 220 / 2 */
    height: 46px; /* 92 / 2 */
    right: 20px;
    bottom: 253.14px; /* 207.14px + 30px */
}

.sample-blocks a:nth-child(7) {
    width: 95px;  /* 190 / 2 */
    height: 49.5px; /* 99 / 2 */
    right: 125px; /* 200 / 1.6 */
    bottom: 320.48px; /* 290.48px + 30px */
}

.sample-blocks a:nth-child(8) {
    width: 80px; /* 160 / 2 */
    height: 43.5px; /* 87 / 2 */
    right: 10px;
    bottom: 306.19px; /* 276.19px + 30px */
}

.sample-blocks a:nth-child(9) {
    width: 65px; /* 130 / 2 */
    height: 33.5px; /* 67 / 2 */
    right: 4px;
    bottom: 360.29px; /* 314.29px + 30px */
}

.sample-blocks a:nth-child(10) {
    width: 150px; /* 300 / 2 */
    height: 98px; /* 196 / 2 */
    left: 15px;
    bottom: 18px; /* -20px + 30px */
}

.sample-blocks a:nth-child(11) {
    width: 50px; /* 100 / 2 */
    height: 67px; /* 134 / 2 */
    left: 183px; /* 320 / 1.6 */
    bottom: 32px; /* 30px + 30px */
}

.sample-blocks a:nth-child(12) {
    width: 130px; /* 260 / 2 */
    height: 85px; /* 170 / 2 */
    left: 0px;
    bottom: 130px; /* 100px + 30px */
}

.sample-blocks a:nth-child(13) {
    width: 42.5px; /* 85 / 2 */
    height: 67px; /* 134 / 2 */
    left: 150px; /* 280 / 1.6 */
    bottom: 142.76px; /* 104.76px + 30px */
}

.sample-blocks a:nth-child(14) {
    width: 40px; /* 80 / 2 */
    height: 46px; /* 92 / 2 */
    left: 106.25px; /* 170 / 1.6 */
    bottom: 245px; /* 200px + 30px */
}

.sample-blocks a:nth-child(15) {
    width: 57.5px; /* 115 / 2 */
    height: 58px; /* 116 / 2 */
    left: 20px;
    bottom: 241.62px; /* 197.62px + 30px */
}

.sample-blocks a:nth-child(16) {
    width: 57.5px;
    height: 58px;
    left: 125px; /* 200 / 1.6 */
    bottom: 310.95px; /* 280.95px + 30px */
}

.sample-blocks a:nth-child(17) {
    width: 57.5px;
    height: 58px;
    left: 10px;
    bottom: 296.67px; /* 266.67px + 30px */
}

.sample-blocks a:nth-child(18) {
    width: 57.5px;
    height: 58px;
    left: 0px;
    bottom: 334.76px; /* 304.76px + 30px */
}

}

@media (max-width: 900px) {
  body > .footer .social a:last-child,
  body > .footer .nav a:last-child {
    display: none; } 
  
  .main-img{
    height: 19rem;
  }
}


@media (max-width: 400px) {
  body > .footer .social a:last-child,
  body > .footer .nav a:last-child {
    display: none; } 

    .recap{
      margin: 5px 0;
    }
}

    .media-tech-logo-now{
      height: 25px;
      min-width: 60px;
    }

/*# sourceMappingURL=style.css.map */
